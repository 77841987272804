import { Component } from '@angular/core';

@Component({
  selector: 'vitamui-common-body',
  templateUrl: './vitamui-body.component.html',
  styleUrls: ['./vitamui-body.component.scss'],
})
export class VitamuiBodyComponent {
  constructor() {}
}
