/*
 * Copyright French Prime minister Office/SGMAP/DINSIC/Vitam Program (2019-2020)
 * and the signatories of the "VITAM - Accord du Contributeur" agreement.
 *
 * contact@programmevitam.fr
 *
 * This software is a computer program whose purpose is to implement
 * implement a digital archiving front-office system for the secure and
 * efficient high volumetry VITAM solution.
 *
 * This software is governed by the CeCILL-C license under French law and
 * abiding by the rules of distribution of free software.  You can  use,
 * modify and/ or redistribute the software under the terms of the CeCILL-C
 * license as circulated by CEA, CNRS and INRIA at the following URL
 * "http://www.cecill.info".
 *
 * As a counterpart to the access to the source code and  rights to copy,
 * modify and redistribute granted by the license, users are provided only
 * with a limited warranty  and the software's author,  the holder of the
 * economic rights,  and the successive licensors  have only  limited
 * liability.
 *
 * In this respect, the user's attention is drawn to the risks associated
 * with loading,  using,  modifying and/or developing or reproducing the
 * software by the user in light of its specific status of free software,
 * that may mean  that it is complicated to manipulate,  and  that  also
 * therefore means  that it is reserved for developers  and  experienced
 * professionals having in-depth computer knowledge. Users are therefore
 * encouraged to load and test the software's suitability as regards their
 * requirements in conditions enabling the security of their systems and/or
 * data to be ensured and,  more generally, to use and operate it in the
 * same conditions as regards security.
 *
 * The fact that you are presently reading this means that you have had
 * knowledge of the CeCILL-C license and that you accept its terms.
 */
import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { SubrogationApiService } from '../../api/subrogation-api.service';
import { VitamUISnackBarService } from '../../components/vitamui-snack-bar/vitamui-snack-bar.service';

@Component({
  templateUrl: './subrogation-snack-bar.component.html',
})
export class SubrogationSnackBarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private matSnackBarRef: MatSnackBarRef<SubrogationSnackBarComponent>,
    private subrogationApiService: SubrogationApiService,
    private snackBarService: VitamUISnackBarService,
  ) {}

  close() {
    this.matSnackBarRef.dismiss();
  }

  accept() {
    this.subrogationApiService.accept(this.data.subro.id).subscribe(() => {
      this.snackBarService.open({
        message: 'SUBROGATION.HOME.RESULTS_TABLE.MODAL.ACCEPT_SUBROGATION',
        icon: 'vitamui-icon-link banner-icon',
      });
      this.close();
    });
  }

  decline() {
    this.subrogationApiService.decline(this.data.subro.id).subscribe(() => this.close());
  }
}
