<a [href]="url" *ngIf="!sameApp" (click)="appSelected()">
  <ng-container *ngTemplateOutlet="button"></ng-container>
</a>
<a [routerLink]="url" *ngIf="sameApp" (click)="appSelected()">
  <ng-container *ngTemplateOutlet="button"></ng-container>
</a>

<ng-template #button>
  <div
    class="app-box clickable archive-tile"
    [class.modal-app-box]="isModalMenu"
    [class.archive-tile]="application?.identifier === 'ARCHIVE_APP'"
    [class.settings-app]="application?.category === 'settings'"
    [class.admin-app]="application?.category === 'administrators'"
    [class.user-app]="application?.category === 'users'"
    [class.referential-app]="application?.category === 'referential'"
    [class.opaudit-app]="application?.category === 'opaudit'"
    [class.highlight]="application?.hasHighlight"
    [vitamuiCommonTooltip]="application?.tooltip"
    [vitamuiCommonTooltipShowDelay]="800"
    id="{{ application.identifier }}"
  >
    <i [ngClass]="application?.icon"></i>{{ application?.name }}
    <i *ngIf="application?.hasCustomerList || application?.hasTenantList" class="material-icons chevron-icon">chevron_right</i>
  </div>
</ng-template>
