<div class="menu-container">
  <div class="menu-modal">
    <h2>
      Mes <span>{{ title }}</span>
    </h2>

    <mat-dialog-content>
      <vitamui-common-application-select-content
        *ngIf="menuType === 'APPLICATION'"
        [applications]="applicationConfig.applications"
        [categories]="applicationConfig.categories"
        [isModalMenu]="true"
        (applicationSelected)="closeApplicationMenu()"
      ></vitamui-common-application-select-content>

      <vitamui-common-customer-select-content
        *ngIf="menuType === 'CUSTOMER'"
        [customers]="items"
        [isModalMenu]="true"
        (customerSelected)="closeCustomerMenu()"
      ></vitamui-common-customer-select-content>

      <vitamui-common-tenant-select-content
        *ngIf="menuType === 'TENANT'"
        [tenants]="items"
        [isModalMenu]="true"
        (tenantSelected)="closeTenantMenu()"
      ></vitamui-common-tenant-select-content>
    </mat-dialog-content>
  </div>
</div>
